import {Component} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-root',
  template: '<ngx-spinner></ngx-spinner><router-outlet></router-outlet>',
  styles: [''],
  providers: [NgxSpinnerService]
})
export class AppComponent {
}
