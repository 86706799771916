import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgOpenDialogComponent } from './img-open-dialog.component';
import {MaterialModule} from '@shared/material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PipesModule} from '@shared/pipes/pipes.module';
import { NgxGalleryModule } from 'ngx-gallery';



@NgModule({
  declarations: [ImgOpenDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    NgxGalleryModule
  ],
  entryComponents: [ImgOpenDialogComponent]
})
export class ImgOpenDialogModule { }
