import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@env/environment";
import { TranslocoService } from "@ngneat/transloco";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { Subscription } from "rxjs";

@Component({
  selector: "app-img-open-dialog",
  templateUrl: "./img-open-dialog.component.html",
  styleUrls: ["./img-open-dialog.component.scss"],
})
export class ImgOpenDialogComponent implements OnInit {
  private subscribtion: Subscription = new Subscription();

  currentLang: string;
  photo;
  photoInput;
  imgPhoto;
  ggg;
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor(
    public dialogRef: MatDialogRef<ImgOpenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { photoPath: string },
    private translocoService: TranslocoService
  ) {
    this.subscribtion.add(
      translocoService.langChanges$.subscribe(
        (lang) => (this.currentLang = lang)
      )
    );
  }

  ngOnInit() {
    this.ggg = this.data;
    this.photo = this.ggg.value.split(",");
    for (let i = 0; i < this.photo.length; i++) {
      this.galleryOptions = [
        {
          width: "600px",
          height: "400px",
          thumbnailsColumns: 5,
          imageAnimation: NgxGalleryAnimation.Zoom,
          previewCloseOnClick: true,
          previewCloseOnEsc: true,
          previewSwipe: true,
          previewZoom: true,
          previewFullscreen: true,
          // previewDownload: true,
          previewRotate: true,
        },
        // max-width 800
        {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20,
        },
        // max-width 400
        {
          breakpoint: 400,
          preview: true,
        },
      ];

      this.galleryImages.push({
        small: `${environment.project.urls.default.dm}/images/${localStorage.getItem('layerNames')}/${this.photo[i]}`,
        medium: `${environment.project.urls.default.dm}/images/${localStorage.getItem('layerNames')}/${this.photo[i]}`,
        big: `${environment.project.urls.default.dm}/images/${localStorage.getItem('layerNames')}/${this.photo[i]}`,
      });
    }
  }
  onClose() {
    this.dialogRef.close();
  }
}
