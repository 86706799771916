import { NgModule } from '@angular/core';
import { Routes, RouterModule, NavigationEnd, Router } from '@angular/router';

import 'rxjs/add/operator/filter';

import { AuthGuard } from '@core/guards/auth.guard';
import { RoleGuard } from '@shared/guards/role/role.guard';
import { ROLES } from '@shared/constants/roles';
import { ScrollService } from '@core/services/scroll/scroll.service';


const routes: Routes = [
  {
    path: 'signin',
    loadChildren: () => import('./modules/sign-in/sign-in.module').then(md => md.SignInModule)
  },
  {
    path: 'architecture',
    loadChildren: () => import('./modules/architecture/architecture.module').then(mod => mod.ArchitectureModule),
    canLoad: [AuthGuard], canActivate: [RoleGuard], data: { roles: [ROLES.DUTY_MAP] }
  },
  {
    path: 'gbd',
    loadChildren: () => import('./modules/front-page/search-gbd/search-gbd.module').then(mod => mod.SearchGbdModule),
  },
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path      : '**',
    redirectTo: 'signin'
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        enableTracing: false
    })],
    exports: [RouterModule]
})
export class RoutingModule {

    constructor(
        router: Router,
        scrollService: ScrollService
    ) {
        // Scroll to top
        router.events.filter(evt => evt instanceof NavigationEnd)
            .subscribe(() => {
                scrollService.scrollToTop();
            });
    }
}
