import { Pipe, PipeTransform } from '@angular/core';
  
@Pipe({
    name: 'formatTime'
})
export class FormatTime implements PipeTransform {
  transform(str: string): string {
    str =  str.slice(0, 10);
    return str;
  }
}