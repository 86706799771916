export interface AuthLogoutParams {
  unload?: boolean;
  redirect?: boolean;
  serverLogout?: boolean;
  asyncLogout?: boolean;
  manualLogout?: boolean;
  expires?: number;
}

export const TOKEN_STORAGE_KEY = 'access_token';
