import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { ApiService } from '../http/api/api.service';
import { ScrollService } from './scroll/scroll.service';
import { AuthService } from './auth/auth.service';


@NgModule({
  imports: [],
  providers: [
    ApiService,
    ScrollService,
    AuthService
  ]
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders {
    return {ngModule: ServicesModule};
  }

  constructor( @Optional() @SkipSelf() parentModule: ServicesModule) {
    if (parentModule) {
      throw new Error('ServicesModule has already been loaded. You should only import Services modules in the AppModule only.');
    }
  }

}
