// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  testEnvironment: {
    flag: false,
    access: {
      login: null,
      passcode: null
    }
  },
  project: {
    urls: {
      default: {
        es: 'https://e-jambyl.kz',
        bp: 'https://bp.e-jambyl.kz',
        dm: 'https://dm.e-jambyl.kz',
        sb: 'https://sb.e-jambyl.kz',
        geo: 'https://geo.e-jambyl.kz'
      },
      production: {
        es: 'https://e-jambyl.kz',
        bp: 'https://bp.e-jambyl.kz',
        dm: 'https://dm.e-jambyl.kz',
        sb: 'https://sb.e-jambyl.kz',
        geo: 'https://geo.e-jambyl.kz'
      }
    }
  },
  baseLayerId: 40017,
  workLayerId: 40018,
  integrationLayerId: 40019,
  additionLayerId: 40022,
  gzkLayerName: 'gis:gzk_features_moved',
  gzkLayerOblastName: 'gis:gzk_features_moved_2201_oblast',
  gsNameSpace: 'gis',
  auctionLyrName: 'auction_lands',
  contestLyrName: 'competition',
  freeLandLyrName: 'gu_free_land',
  buildsLyrName: 'builds',
  roads: 'roads',
  streetsLyrName: 'bs_ts_rdc_street_taraz',
  socObjLyrName: 'social_objects',
  borderTaraz: 'border_taraz',
  freeLandPoints: 'gu_free_land_connection_points',
  undevelopedLand: 'neosvoennye_zu',
  landInspection: 'zem_inspectciya',
  school: 'school',
  kindergartens: 'kindergartens',
  sport: 'oo_sport_school',
  roads_exist: 'bs_ts_rdc_road',
  bus_routes: 'transport',
  city_routes: 'city_routes',
  construction: 'construction',
  roads_project: 'roads_project',
  provider: 'providers',
  vols_mshpd: 'vols_mshpd',
  mshpd: 'mshpd',
  microdistrict_internet: 'microdistrict_internet',
  oo_dentisty: 'oo_dentisty',
  zhil_inspectciya: 'zhil_inspectciya',
  gask: 'gask',
  culture_house: 'culture_house',
  monuments: 'monuments',
  museums: 'museum',
  builds: 'builds',
  landscaping: 'bs_blag_zelen',
  betterment: 'betterment',
  tpi: 'tpi',
  opi: 'opi',
  acreage_use: 'acreage_use',
  pasture_use: 'pasture_use',
  architecture: 'architecture',
  seismic_resistance: 'seismic_resistance',
  free_city_land: 'free_city_land',
  free_gov_agro_land: 'free_gov_agro_land',
  architecture_corrected: 'architecture_corrected',
  libraries: 'library',
  address_plan: 'address_plan',
  oo_hospital: 'oo_hospital',
  oo_polyclinic: 'oo_polyclinic',
  oo_labs: 'oo_labs',
  oo_club: 'oo_club',
  oo_sport_object: 'oo_sport_object',
  krasnie_linyi: 'pdpregredlinelin_raion',
  gp_all_np: 'gp_all_np',
  pdpregredlinelin_raion: 'pdpregredlinelin_raion',
  gpregredlinelin_raion: 'gpregredlinelin_raion',
  gu_temporary_land: 'gu_temporary_land',
  additional_education: 'additional_education',
  colleges: 'colleges',
  tbo_monitoring: 'tbo_monitoring'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
