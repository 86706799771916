import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ApiService} from '@core/http/api/api.service';
import {CONFIG} from '@app/configs/config';
import {User} from '@shared/models/user.model';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {AuthLogoutParams, TOKEN_STORAGE_KEY} from '@shared/models/auth.model';

@Injectable()
export class AuthService {
  public currentUser$: Observable<User>;
  public redirectUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private userIsLogged?: boolean;
  private unloadFlag?: boolean;
  private keepAuth?: boolean;

  constructor(
    private api: ApiService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.listenBeforeUnloadEvents();
    this.init();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.getValue();
  }

  public setCurrentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  public isAuthenticated(): boolean {
    const token: string = this.getToken();
    return this.isValidToken(token);
  }

  public getTokenData(): any {
    let tokenData: any;
    try {
      tokenData = this.jwtHelper.decodeToken(this.getToken());
    } catch {
      tokenData = null;
    }
    return tokenData;
  }

  public getCurrentUser(): any {
    const tokenData = this.getTokenData();
    if (tokenData && tokenData.userId) {
      if (this.currentUserSubject.getValue()) {
        return this.currentUserSubject.asObservable();
      } else {
        return this.api.get(CONFIG.EQYZMET_SERVICES_URL + `users/${tokenData.userId}`)
          .pipe(map(data => {
            this.setCurrentUserValue(data);
            return data;
          }));
      }
    }
    return null;
  }

  login(username: string, password: string) {
    return this.httpClient.post(`/auth/token`, { username, password });
  }

  public logout(
    {
      unload = false,
      redirect = true,
      serverLogout = true
    }: AuthLogoutParams = {}
  ): void {
    // remove user from local storage to log user out
    const token: string = this.getToken();
    if (serverLogout) {
      this.logoutFromPortal(token);
    }
    this.removeToken();
    if (!unload) {
      this.currentUserSubject.next(null);
      if (redirect) {
        this.router.navigate(['signin']).then();
      }
    }
  }

  public setToken(token: string): void {
    localStorage.setItem('access_token', token);
    this.setUserLogFlag(true);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
  }

  public setKeepAuthFlag(flag: boolean = true): void {
    this.keepAuth = flag;
    this.updateUnloadFlag();
  }

  private init(): void {
    this.userIsLogged = !!this.getToken();
    this.updateUnloadFlag();
  }

  private isValidToken(token: string): boolean {
    try {
      return !!this.jwtHelper.decodeToken(token) && !this.jwtHelper.isTokenExpired(token);
    } catch {
      return false;
    }
  }

  private logoutFromPortal(token: string): void {
    if (token) {
      navigator.sendBeacon(`/auth/logout?token=${token}`);
    }
  }

  private removeToken(): void {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    this.setUserLogFlag(false);
  }

  private updateUnloadFlag(): void {
    this.unloadFlag = this.userIsLogged && !this.keepAuth;
  }

  private listenBeforeUnloadEvents(): void {
    if (environment.production) { // true || при перезагрузке страницы выходить с аккаунта усли true
      window.addEventListener('beforeunload', () => {
        if (this.unloadFlag) {
          this.logout();
        }
      });
    }
  }

  private setUserLogFlag(flag: boolean): void {
    this.userIsLogged = flag;
    this.updateUnloadFlag();
  }

  public get tokenStorageKey(): string {
    return TOKEN_STORAGE_KEY;
  }

  public get tokenFromStorage(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
  }
}
