import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {GestureConfig} from '@angular/material';
import {RoutingModule} from './routing.module';

import {AppComponent} from './app.component';
import {DirectivesModule} from './shared/directives/directives.module';
import {ServicesModule} from './core/services/services.module';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {JwtInterceptor} from './core/interceptors/jwt.interceptor';
import {TranslocoRootModule} from './transloco-root.module';
import {ErrorDialogModule} from '@shared/components/error-dialog/error-dialog.module';
import {en_US, NgZorroAntdModule, NZ_I18N} from 'ng-zorro-antd';
import {FormsModule} from '@angular/forms';
import en from '@angular/common/locales/en';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FormatTime} from '@app/shared/pipes/formatTime.pipe';
import { ImgOpenDialogModule } from './shared/components/img-open-dialog/img-open-dialog.module';

registerLocaleData(en);


@NgModule({
  declarations: [
    AppComponent, FormatTime
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ServicesModule.forRoot(),
    DirectivesModule,
    NgxSpinnerModule,
    RoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    ImgOpenDialogModule,
    ErrorDialogModule,
    NgZorroAntdModule,
    FormsModule,
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: (() => localStorage.getItem('access_token')),
    //     whitelistedDomains: ['localhost:3000'],
    //     blacklistedRoutes: ['http://localhost:3000/auth/login']
    //   }
    // }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: NZ_I18N, useValue: en_US},

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
