import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import {ErrorDialogComponent} from '@shared/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private dialog: MatDialog
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError( (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            this.showErrorDialog({ errorMessage: err.message });
          } else {
            // this.showErrorDialog({ errorTitle: err.status, errorMessage: err.statusText });
            if (err.status === 200) {
                 return;
            } else if (err.status === 400 || err.status === 404 || err.status === 403 || err.status === 401 || err.status === 500) {
              this.showErrorDialog({ errorMessage: err.error.message});
            } else if (err.status === 504) {
              this.showErrorDialog({errorMessage: 'Запрос не выполнен'});
            } else {
              this.showErrorDialog({ errorTitle: err.status, errorMessage: err.statusText });
            }
            switch (err.status) {
              case 401:
                // auto logout if 401 response returned from api
                this.authService.logout();
                break;
              case 403:
                // auto logout if 403 response returned from api
                this.authService.logout();
                break;
            }
          }
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  private showErrorDialog(errorMessage: { errorTitle?: any, errorMessage: any}) {
    this.dialog.open(ErrorDialogComponent, {
      data: errorMessage,
      height: '275px',
      width: '550px',
      position: { top: `50px` },
    });
  }
}
