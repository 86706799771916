import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly destroyed$: Subject<void>;
  private readonly tokenLocalStorageKey: string;
  private readonly tokenSender$: BehaviorSubject<string>;

  constructor() {
    this.destroyed$ = new Subject<void>();
    this.tokenLocalStorageKey = 'access_token';
    this.tokenSender$ = new BehaviorSubject<string>(this.tokenFromStorage);

    window.addEventListener('unload', (event: Event) => {
      this.tokenSender$.complete();
      this.destroyed$.next();
      this.destroyed$.complete();
    });
  }

  private get tokenFromStorage(): string {
    return localStorage.getItem(this.tokenLocalStorageKey);
  }

  get tokenStorageKey(): string {
    return this.tokenLocalStorageKey;
  }

  removeToken(): void {
    localStorage.removeItem(this.tokenLocalStorageKey);
  }

  getToken(): Observable<string> {
    return this.tokenSender$.asObservable().pipe(
      // filter((token: string) => !!token),
      // skipWhile((token: string) => !token),
      takeUntil(this.destroyed$)
    );
  }

  sendToken(token: string): void {
    localStorage.setItem(this.tokenLocalStorageKey, token);
    this.tokenSender$.next(this.tokenFromStorage);
  }

  clearToken(): void {
    this.removeToken();
    this.tokenSender$.next(null);
  }
}
