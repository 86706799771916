import { NgModule } from '@angular/core';
import { MatchHeightDirective } from './match-height/match-height.directive';
// import { SwitchLayerIconsDirective } from './switch-layer-icons/switch-layer-icons.directive';


@NgModule({
  declarations: [MatchHeightDirective],
  exports: [MatchHeightDirective]
})
export class DirectivesModule { }
