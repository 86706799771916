import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONFIG } from '@app/configs/config';
import { TokenService } from '@app/core/services/auth/token.service';
import { take } from 'rxjs/internal/operators/take';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService) {}

  private get getToken(): Observable<string> {
    return this.tokenService.getToken().pipe(
      take(1)
    );
  }

  public get(url: string, parms?): Observable<any> {
    return this.httpClient.get(url, {
      params: parms,
    });
  }

  get2(restPath: string, parms?, auth = true): Observable<any> {
    return this.getToken.pipe(
      switchMap((token: string) => {
        const url: string = CONFIG.DUTYMAP_SERVICES_URL + restPath;
        return this.httpClient.get(url, {
          params: parms,
        });
      })
    );
  }

  public getFromGeoportal(restPath: string, parms?): Observable<any> {
    const token = localStorage.getItem('token');
    const url: string = CONFIG.GEOPORTAL_SERVICES_URL + restPath + '?token=' + token;
    return this.httpClient.get(url);
  }

  public getArrayBuffer(url: string, params: any = null): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(url, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  public post(url: string, body: any = null, parms?): Observable<HttpResponse<any>> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.post<any>(url, body, {
      headers: new HttpHeaders(headers),
      observe: 'response',
      params: parms
    });
  }

  post2(restPath: string, body: any = null, parms?, auth = true): Observable<HttpResponse<any>> {
    return this.getToken.pipe(
      switchMap((token: string) => {
        const url: string = CONFIG.DUTYMAP_SERVICES_URL + restPath;
        const headers = auth ? {
          'Content-Type': 'application/json'
        } : {};
        return this.httpClient.post<any>(url, body, {
          headers: new HttpHeaders(headers),
          observe: 'response',
          params: parms
        });
      })
    );
  }

  public postArrayBuffer(url: string, body: any = null, params: any = null): Observable<HttpResponse<any>> {
    return this.httpClient.post<any>(url, body, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  public put(url: string, body = null, params: any = null): Observable<HttpResponse<any>> {
    return this.httpClient.put<any>(url, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    });
  }

  public delete(url: string, parms?): Observable<any> {
    return this.httpClient.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: parms,
      observe: 'response'
    });
  }

}
