import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(public authService: AuthService, public router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles = next.data.roles;
        const token = this.authService.getTokenData();
        if (this.authService.isAuthenticated()) {
            const filteredRoles = roles.filter((role) => token.authorities.includes(role));
            return filteredRoles && filteredRoles.length ? true : false;
        }
        this.router.navigate(['404']);
        return false;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles = next.data.roles;
        const token = this.authService.getTokenData();
        if (this.authService.isAuthenticated()) {
          const filteredRoles = roles.filter((role) => token.authorities.includes(role));
          return filteredRoles && filteredRoles.length ? true : false;
        }
        this.router.navigate(['404']);
        return false;
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

}
