import { Directive, Input, ElementRef, AfterViewChecked, HostListener, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appMatchHeight]'
})
export class MatchHeightDirective implements AfterViewChecked, OnDestroy {

    @Input('appMatchHeight') elementId: string;

    constructor(private el: ElementRef) { }

    ngAfterViewChecked() {
        this.matchHeight();
    }

    @HostListener('window:resize')
    onResize() {
        // call our matchHeight function here later
        this.matchHeight();
    }

    matchHeight() {
        if (!this.el.nativeElement || !this.elementId) { return; }

        const element = document.getElementById(this.elementId);
        const maxHeight = window.innerHeight;
        this.el.nativeElement.style.height = (maxHeight - element.offsetHeight) + 'px';
    }

    ngOnDestroy() {
    }
}
