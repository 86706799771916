import {environment} from '@env/environment';

export abstract class CONFIG {
  static readonly EQYZMET_SERVICES_URL = '/eqyzmet/api/';
  static readonly DUTYMAP_SERVICES_URL = '/dutymap/api/';
  static readonly GEOSERVER_URL = '/geoserver/';
  static readonly GEOPORTAL_SERVICES_URL = '/atyraugis/';
  static readonly GEOPORTAL_REST_SERVICES_URL = '/atyraugis/rest/';
  static readonly SED_API = '/sedapi/';
  static readonly GEO_INTEGRATION_API = '/integration/api/';
  static readonly EQYZMET_STORAGE = '/eqyzmet/storage/';
  static readonly FILESERVER = '/fileserver/api/';
}

export abstract class LAYERS {
  static readonly GZK = `${environment.gzkLayerName}`;
}
